.App {
  text-align: center;
}

.nav {
  display: flex;
  justify-content: space-between;
  margin-right: 43px;
  margin-left: 43px;
}

.links {
  display: flex;
  align-items: center;
}

.heading {
  padding-right: 20px;
  padding-left: 20px;
  color: rgb(136, 133, 133);
  text-decoration: none;
  font-size: 12px;
}

.clickedLink {
  padding-right: 20px;
  padding-left: 20px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  text-decoration: underline;
}

.checker {
  color: rgb(255, 255, 255);
  text-decoration: underline;
}

.filterIcon {
  display: flex;
  align-items: center;
}

.filterIcon p {
  margin-left: 10px;
  color: rgb(255, 255, 255);
}

.main {
  background-color: rgb(16, 16, 16);
  max-height: 84px;
  max-width: 1366px;
  border-radius: 0;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user {
  display: flex;
  margin-right: 19.72px;
  align-items: center;
  padding: 10px;
}

.user p {
  margin-right: 20px;
}

.title {
  height: 43px;
  width: 114px;
  left: 43px;
  top: 21px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-left: 43px;

  /* line-height: 43px; */
}

.data-body {
  background-color: rgb(23, 23, 23);
  display: flex;
  margin-right: 43px;
  margin-left: 43px;
  border-radius: 10px;
  align-items: center;
  margin-top: 20px;
  padding: 30px;
  color: wheat;
}

.location {
  display: flex;
  margin-right: 43px;
  max-height: 40px;
}

.img{
  height: 145px;
}

.location p {
  background-color: rgb(16, 16, 16);
  color: rgb(255, 255, 255);
  border-radius: 16px;
  padding: 10px 20px 0 20px;
}

.location p:first-child {
  margin-right: 20px;
}

.navs {
  margin-bottom: 30px;
}

.info {
  display: flex;
}

.img {
  margin-right: 43px;
}

.details {
  text-align: left;
  min-width: 550px;
  line-height: 2;
}

.para {
  color: rgb(207, 207, 207);
}

.span {
  color: rgb(255, 255, 255);
}

.filters {
  display: flex;
  margin-right: 43px;

  /* margin-bottom: 40px; */
  justify-self: end;
  flex-direction: column;
  margin-left: 85%;
  max-width: 150px;
}

.select {
  background-color: rgb(16, 16, 16);
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin-right: 10px;
}
