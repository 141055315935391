* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: SF Pro Display, Inter, Helvetica, sans-serif;
  background-color: rgb(41, 41, 41);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
